@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

.login-banner-text {
  font-family: "Manrope", sans-serif;
  color: #001833;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 129%; /* 30.96px */
  letter-spacing: -0.28px;
}

.scholarcred-text {
  color: #00a6d9;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 129%;
  letter-spacing: -0.28px;
}

.login-charector-img {
  object-fit: contain;
  height: auto;
  max-height: 95vh;
  min-height: 70vh;
}

.table-responsive table {
  border-collapse: collapse;
  width: 100%;
}

.table-responsive th,
.table-responsive td {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

.table-responsive th {
  background-color: #f2f2f2;
}

.custom_btn {
  font-size: 10px;
}


/* Profile Circle  */
.circle {
  display: inline-block;
    background-color: #00d53b;
    /* margin: 10px; */
    margin-right: 10px;
    border-radius: 50%;
}

.circle-inner {
  color: white;
  display: table-cell;
  vertical-align: middle; 
  text-align: center;
  text-decoration: none;
  height: 30px;
  width: 31px;  
  font-size: 12px;
}

.content-page{
  overflow: hidden;
}
.noBorderTable {
  border-collapse: collapse;
  border: none;
}

.noBorderTable th, .noBorderTable td {
  border: none;
}

@keyframes highlight {
  0% { background-color: #E4A11B; }
  100% { background-color: white; }
}

.highlighted,.highlighted-userbox {
  animation: highlight 1s ease-out;
}

.ant-drawer-content-wrapper {
  position: absolute;
  right: 0;
  width: 30% !important;
}

/* Apply media query for smaller screens */
@media screen and (max-width: 768px) {
  .ant-drawer-content-wrapper {
    width: 60% !important; 
  }
}



